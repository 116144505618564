<i18n locale="th" lang="yaml" >
onfield_service_repair.section.event : "รายละเอียดปัญหา"
onfield_service_repair.section.repair : "การซ่อม"
onfield_service_repair.section.parts : "รายการอะไหล่งานซ่อม"
onfield_service_repair.section.inspection : "ข้อมูลตรวจสอบหลังซ่อม"
</i18n>

<template>
	<div class="repair-container">
		<ErrorMessagePane :error="error" :only-model-keys="['job_repair']"/>

		<div class="repair-section-container">
			<div class="repair-section-title">
				{{$t('onfield_service_repair.section.event')}}
			</div>
			<ServiceRepairInput ref="repairInputRef" class="repair-subsection-container"
				:disable-mode="true" :can-update="true" :service="service"/>
		</div>

		<div class="repair-section-container">
			<div class="repair-section-title">
				{{$t('onfield_service_repair.section.repair')}}
			</div>
			<ServiceRepairDetailInput ref="repairDetailInputRef" class="repair-subsection-container"
				:can-update="true" :service="service"/>
		</div>
		<div class="repair-section-container">
			<div class="repair-section-title">
				{{$t('onfield_service_repair.section.parts')}}
			</div>
			<ErrorMessagePane :error="error" :only-model-keys="['service_parts_repair']"/>
			<ServicePartList ref="repairPartsRef" :service="service"
				:readonly="false"
				:can-modify-row="true"
				:is-onsite="true"
				mode="repair"/>
		</div>
	</div>
</template>

<script>
import ServiceRepairInput from "@components/service/ServiceRepairInput.vue"
import ServiceRepairDetailInput from "@components/service/ServiceRepairDetailInput.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import ServicePartList from "@components/service/ServicePartList.vue"

export default {
	components : {
		ServiceRepairInput,
		ServiceRepairDetailInput,
		ServicePartList ,
		ErrorMessagePane ,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		error : {
			type : null,
			default : undefined,
		},
		isOnsite: {
			type : Boolean,
			default : false,
		},
	} ,
	methods : {
		setData(data, serviceParts) {
			if (!data.id || data.id <= 0)  {
				data = {
					checklist_type : this.service.checklist_type ,
					is_not_inspect : Boolean(this.service.is_device_only)
				}
			}
			if (data) {
				this.$refs.repairInputRef.setData(data)
				this.$refs.repairDetailInputRef.setData(data)
			}
			if (serviceParts?.repair) {
				this.$refs.repairPartsRef.setData(serviceParts.repair)
			} else {
				this.$refs.repairPartsRef.setData([])
			}
		} ,
		getData() {
			return {
				... this.$refs.repairInputRef.getData() ,
				... this.$refs.repairDetailInputRef.getData() ,
			}
		} ,
		getPartData() {
			const partDatas = this.$refs.repairPartsRef.getData()
			return partDatas
		} ,
		validate() {
			return this.$refs.repairPartsRef.validate()
		}
	}
}
</script>

<style lang="less" scoped>
.repair-section-title {
	font-family : @font-family-title;
	text-decoration: underline;
	font-size : 1.1em;
	color : @secondary-color;
	margin-bottom : 12px;
}
.repair-subsection-container {
	padding-left : 24px;
}
</style>
